<template>
  <div class="app">
    <apexcharts
      height="auto"
      :width="graphWidth"
      type="line"
      class="ma-auto"
      :options="chartOptions"
      ref="realtimeChart"
      :series="series"
      v-if="loadingDone"
    ></apexcharts>
    <v-date-picker
      v-model="picker"
      width="100%"
      type="month"
      class="mt-10 pa-5"
      @change="changeDate()"
    ></v-date-picker>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import firebase from "firebase";
import moment from "moment";
import "firebase/firestore";
// import { doc, getDoc } from 'firebase/firestore';
// import { doc } from "firebase/firestore";

export default {
  name: "Chart",
  components: {
    apexcharts: VueApexCharts,
  },
  data: function () {
    return {
      chartOptions: {
        chart: {
          id: "vuechart-example",
        },
        markers: {
          size: 0.5,
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          min: 0,
          max: 10,
        },
        legend: {
          position: "right",
          verticalAlign: "top",
          containerMargin: {
            left: 35,
            right: 60,
          },
        },
        responsive: [
          {
            breakpoint: 1000,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      series: [
        {
          name: "Floris",
          data: [],
        },
        {
          name: "Robbert",
          data: [],
        },
      ],
      dateArray: [],
      numberData: [],
      userIDs: ["oAj3acuHACVAFXy8pv5hkxBarfD2", "yL1Lxkv9dlgnwh4tMR4AyYWt2483"],
      loadingDone: false,
      graphWidth: "100%",
      picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),      
      date: null,
      month: null,
      year: null,
    };
  },
  created: function () {   
    this.changeDate();
  },

  methods: {
    changeDate(){
      this.dateArray = [];
      var date = new Date(this.picker);
      this.date = date;
      this.month = moment(date).format("MM");
      this.year = moment(date).format("YYYY");
      this.changeGraph();
    },
    changeGraph(){
    var _self = this;
    _self.isMobile();
    var currentDate = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    var stopDate = new Date();

    Date.prototype.addDays = function (days) {
      var date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };

    while (currentDate <= stopDate) {
      let tempDate = new Date(currentDate);
      _self.dateArray.push(moment(tempDate).format("DD-MM-YYYY"));
      currentDate = currentDate.addDays(1);
    }

    _self.chartOptions.xaxis.categories = _self.dateArray;
    for (let i = 0; i < _self.userIDs.length; i++) {
      var tutorialsRef = firebase
        .firestore()
        .collection("users")
        .doc(_self.userIDs[i])
        .collection("data")
        .doc(_self.year)
        .collection(parseInt(_self.month).toString());
      tutorialsRef.onSnapshot(function (snapshot) {
        snapshot.forEach((doc) => {
          _self.loadingDone = false;
          let date = new Date(doc.data().date);
          for (let x = 0; x < _self.dateArray.length; x++) {
            if (_self.dateArray[x] == moment(date).format("DD-MM-YYYY")) {
              _self.series[i].data[x] = doc.data().number;
            } else {
              if (_self.series[i].data[x] == 0) {
                _self.series[i].data[x] = 0;
              }
            }
          }
        });

        if (_self.$refs[i] !== undefined) {
          _self.$refs[i].realtimeChart.updateSeries(
            [
              {
                data: _self.series[i].data,
              },
            ],
            false,
            true
          );
        }

        setTimeout(function () {
          _self.loadingDone = true;
        }, 250);
      });
    }
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        this.graphWidth = "100%";
      } else {
        this.graphWidth = "50%";
      }
    },
    // this.numberData.push({tutorials});
    // console.log(this.numberData);
  },
};
</script>

<style>
.apexchartsvuechartxexample {
  margin: 0 auto !important;
}
</style>
