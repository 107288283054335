<template>
  <div id="app">
    <Chart />
  </div>
</template>

<script>
import Chart from "../components/Chart.component.vue";

export default {
  name: "app",
  components: {
    Chart,
  },
};
</script>
